@import "../../styles/common/card";
@import "../../styles/common/colors";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";

.category-opportunity {
  margin-bottom: 60px;
}

.opportunity-card {
  cursor: pointer !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include card;
  min-height: 650px;
  height: auto !important;
  width: 100%;
  text-decoration: none;
  color: $primary500;
  padding: 0 0 8px 0;
  transition: filter 0.3s ease, transform 0.3s ease;
  &:hover {
    transform: scale(1.02); // Subtle zoom effect for engagement
  }
}

.opportunity-card-img {
  width: 100%;
  aspect-ratio: 4 / 3; // Mobile-friendly ratio (taller for better visibility)
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-size: cover;
  background-position: center; // Ensures centered images
  background-repeat: no-repeat;
  cursor: default !important;
}

.opportunity-card-slider {
  width: 100% !important;
}

.opportunity-loading-card {
  width: 100%;
}

.opportunities-page-container {
  background-color: $secondary100;
  padding: 16px;
}

.opportunities-container {
  align-items: stretch !important;
}

.opportunity-card-top {
  padding: 12px 16px;
  &__labels {
    align-items: flex-start;
  }
}

.opportunity-title {
  color: $primary400;
  margin-bottom: 24px;
}

.has-interest {
  flex-direction: row !important;
  align-items: center;
}

.border-grey {
  border-bottom: 1px solid $grey300;
  width: 95%;
  margin: 0 auto;
}

.opportunity-card-bottom {
  padding: 24px 16px;
}

.opportunity-card-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  svg {
    color: $secondary600;
    width: 18px;
    height: 18px;
  }
}

.opportunity-card-btn {
  margin: 0 0 20px 0;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  &__dashboard {
    width: 78%;
  }
}

.info-label {
  color: $grey700;
  display: flex;
  align-items: center;
  word-break: break-word;
  gap: 5px;
}

.info-value {
  color: $info500;
  font-size: 16px !important;
  font-weight: bold;
}

.opportunity-card-content {
  color: $grey700;
  font-size: 13px;
  margin: 0;
  min-height: 80px;
  padding-bottom: 24px;
}

.opportunities-form {
  display: flex;
  gap: 24px;
  margin: 24px 0;
  width: 100%;
  font-size: 13px;
  & > * {
    width: 100%;
  }
}
.mobile-opportunities-filters {
  display: none;
}

.MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  color: $grey600 !important;
}
.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: $grey800 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: $grey400 !important;
  border-radius: 2px !important;
}
.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $primary500 !important;
}
.MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: $primaryLightGradient !important;
  color: $primary500;
}

.opportunity-btn {
  @include btnLargeMainColored($primary500, $primary600);
}

.category-title-container {
  display: flex;
  align-items: center;
  gap: 16px;
  color: $grey600;
}

.opportunities-loader-container {
  display: flex;
  flex-direction: column;
}

.private-product-container {
  background: $grey200;
  color: $grey300;
  padding: 8px 24px;
  border-radius: 2px;
  p {
    color: $grey800;
  }
}

.large-card {
  width: 100%;
  border-radius: 4px;
  min-height: 350px;
  color: $white;
  background: rgb(10,38,42);
  background: -moz-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a262a",endColorstr="#236575",GradientType=1);
  padding: 16px;
  @include cardShadow;
  &__label-value {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: $white;
  }
  &__label-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  &_img {
    min-height: 400px;
    width: 60%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.large-card-category-content {
  align-items: flex-start;
  justify-content: space-between;
  .opportunity-card-img-label {
    padding-left: 0;
  }
}
.large-card-top-content-container {
  padding: 16px;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  color: $white;
}
.large-card-top-content {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: center;
  .opportunity-card-img-label {
    padding: 0;
  }
}

.large-card-bottom-container {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.large-card-infos-container {
  margin-bottom: 24px;
}

.large-card-name-container {
  .large-card-content {
    max-width: 620px;
    font-size: 14px;
    color: $white;
    font-weight: normal;
  }
  .large-card-name {
    color: $white;
  }
}

.large-card-os-logo {
  width: 120px;
  height: auto;
}

.blurred-card {
  border-right: none;
  font-size: 14px;
  color: $grey300;
  .tooltip-btn {
    color: $grey300;
  }
}

.interest-card-btns {
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 24px;
}


@include forTabletPortraitUp {
  .opportunities-page-container {
    padding: 16px 32px;
  }
  .opportunity-card-btn {
    &__dashboard {
      width: 95%;
    }
  }
  .interest-card-btns {
    justify-content: flex-end;
    align-items: center;
  }
}

@include forTabletLandscapeUp {
  .opportunities-form {
    & > * {
      width: 48%;
    }
  }
  .mobile-opportunities-filters {
    display: none;
  }
  .opportunity-loading-card {
    width: 48%;
  }
  .opportunities-loader-container {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .opportunity-card-btn {
    &__dashboard {
      width: 95%;
    }
  }
  .has-interest {
    align-items: center;
  }
  .opportunity-card-top {
    &__labels {
      justify-content: space-between;
      align-items: center;
    }
  }

  .large-card-top-content {
    align-items: center;
  }
  .large-card-category-content {
    align-items: center;
    justify-content: space-between;
  }
  .blurred-card {
    border-right: 1px solid $grey500;
    padding: 8px 28px 8px 12px;
    &__no-border {
      border: none;
    }
  }
  .large-card-infos-container {
    margin-bottom: 0;
  }
  .large-card-bottom-container {
    align-items: flex-end;
  }
}

@include forDesktopUp {
  .opportunity-card-btn {
    &__dashboard {
      width: 95%
    }
  }
  .opportunity-card-content {
    min-height: 60px;
  }
  .opportunity-card-img {
    aspect-ratio: 16 / 9; // Wide for desktop
    object-fit: cover;
  }
}

@include forPhoneOnly {
  .opportunity-card-btn {
    &__dashboard {
      width: 81% !important;
    }
  }
}


