@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/card";

.auth-container {
  padding: 16px;
  background: $secondary100;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green-bg {
  background-image: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 100%),url('https://cdn.prod.website-files.com/677429718f831a28c6974ae1/6775c2737c724ee9d0e48d6b_coverhome7.avif');
  background-size: cover;
  background-repeat: no-repeat;
}

.livretp-bg {
  background: rgb(19,50,81);
  background: -moz-linear-gradient(90deg, #133251 0%, #1C4C75 36%, #205886 64%, #225F8F 100%);
  background: -webkit-linear-gradient(90deg, #133251 0%, #1C4C75 36%, #205886 64%, #225F8F 100%);
  background: linear-gradient(90deg, #133251 0%, #1C4C75 36%, #205886 64%, #225F8F 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#133251", endColorstr="#225F8F", GradientType=1);
}
.auth-image-container {
  display: none;
  height: 100vh;
  width: 65%;
  background-size: cover;
  background-repeat: no-repeat;
}

.auth-os-logo {
  width: 200px;
  height: auto;
  &-drawer {
    width: 150px;
  }
}
.auth-phone-container {
  color: $grey600;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.info-callout {
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  margin: 4px 0 16px 0;
  color: $primary500;
  border-radius: 4px;
  padding: 4px 8px 4px 16px;
  background-color: $grey200;
  width: 93% !important;
  font-size: 14px;
  p {
    color: $primary500 !important;
  }
  a {
    padding-left: 0;
  }
  &__blue {
    background-color: $grey200;
    color: $info700;
    border: 1px solid $info500;
    p {
      color: $info500 !important;
    }
  }
}

.auth-back {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: $white;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 24px;
  width: 100%;
}

.signup-link {
  color: $white;
  border-bottom: 1px solid $white;
  text-decoration: none;
  font-size: 12px;
  &-grey {
    color: $grey700;
    border-bottom: 1px solid $grey700;
  }
}

.signup-text {
  color: $white;
  margin-bottom: 24px;
  font-size: 13px;
  display: flex;
  gap: 8px;
}

.labels {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 70%;
  }
}

.labels-signup {
  display: none;
}

.top-container {
  align-items: flex-start;
  width: 100%;
}

.badges-page-container {
  align-items: center;
  flex-direction: row !important;
  gap: 8px;
}

.image-section{
  display: none;
}

.top-form {
  align-items: flex-start;
}

.sign-up-top-container {
  justify-content: space-between;
}

.logo-container {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.auth-title {
  color: $white !important;
  align-items: center;
}


@include forTabletPortraitUp {
  .info-callout {
    flex-direction: row !important;
    align-items: center;
    width: 97% !important;
    a {
      padding-left: 24px;
    }
  }
  .logo-container {
    width: 80%;
  }
  .top-form{
    width: 100%;
    flex-direction: row !important;
    align-items: center;
    gap: 8px;
    span {
      margin:0;
    }
}

@include forTabletLandscapeUp {
  .labels {
    img {
      width: 40%;
    }
  }
  .auth-phone-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
  .auth-image-container {
    display: block;
  }
  .auth-back {
    width: 100%;
  }
  .top-container {
    align-items: flex-start;
    justify-content: space-between;
  }
}


.avantages-container{
  span > p {
    color: $secondary100 !important;
  }
}


@include forDesktopUp {

  .image-section{
    display: block;
  }
  .labels-signup {
    margin-top: 40px;
    display: block;
    bottom: 0;
    width: 100%;
    img {
      width: 95% !important;
    }
  }
}}
