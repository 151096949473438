@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/mixins";

.layout-container {
  display: flex;
  position: relative;
}

.page-container {
  background-color: $secondary100;
  width: 100%;
  height: 100%;
}

.drawer {
  display: none !important;
}

.mobile-nav {
  display: block;
}

.product-finalise-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  p {
    margin-top: 0 !important;
  }
}

.product-finalise-img {
  width: 80px;
  height: 80px;
  border-radius: 2px;
}

.product-finalise-close {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 20px !important;
  height: 20px !important;
  color: $grey600;
  cursor: pointer;
}

#yousign-iframe-container {
  height: 600px !important;
  width: 100% !important;
  border: none !important;
  border-radius: 5px;
  iframe {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    border-radius: 5px;
  }
}

#yousign-iframe {
  height: 600px !important;
  width: 100% !important;
  border: none !important;
  border-radius: 5px;
  iframe {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    border-radius: 5px;
  }
}


@include forTabletLandscapeUp {
  .page-container {
    width: calc(100% - 180px);
  }
  .drawer {
    display: block !important;
  }
  .mobile-nav {
    display: none;
  }
}

@include forDesktopUp {
  .page-container {
    width: calc(100% - 200px);
  }
}
