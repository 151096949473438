@import "../../styles/common/colors";

.instructions-container {
    background-color: $secondary100;
    padding: 2px 16px 8px 16px;
    border-radius: 2px;
    margin: 8px 0;
    color: $secondary800;
}

.instructions-container-link {
    color: $secondary800;
    text-decoration: none;
    font-weight: 600;
}
