@import '../../styles/common/card';
@import '../../styles/common/buttons';
@import '../../styles/common/mixins';
@import '../../styles/common/colors';

.MuiTypography-body1 {
  font-size: 13px !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: $primaryLightGradient !important;
}

.user-survey-container {
  padding: 32px 16px;
  background-color: $secondary100;

  .product-document {
    width: 100% !important;
  }
}

.user-survey-card {
  border: 1px solid $secondary400;
  padding: 16px;
  border-radius: 2px;
  margin-top: 24px;
  height: auto;
}

.user-survey-questions-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 0;
  & > * {
    width: 100% !important;
  }
  .full-input {
    width: 100% !important;
  }
  .radio-inputs {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 11px;
  }
  &-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
  }
  .statement-checkbox {
    min-width: 7%;
    max-width: 7%;
  }
}

input[type='radio'] {
  width: 16px !important;
  height: 16px !important;
  accent-color: $primary500 !important;
  color: $primary500 !important;
  cursor: pointer !important;
}
input[type='checkbox'] {
  width: 16px !important;
  height: 16px !important;
  accent-color: $primary500;
  color: $primary500 !important;
  cursor: pointer !important;
}
label {
  color: $primary500;
  font-size: 14px !important;
}

.top-button-container{
  justify-content: space-between;
  align-items: center;
}
.top-survey-container {
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@include forPhoneOnly {
  .share-text {
    display: none;
  }
}

.top-button-container{
  flex-direction: row !important;
}


.share-icon {
  font-size: 1.5em;
}

.user-survey-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.upload-btns-container {
  justify-content: space-between;
}

.user-survey-btn {
  @include btnLargeMainColored($primary500, $primary600);
  width: fit-content;
}

.user-survey-btn__secondary {
  @include btnSecondaryColored($primary500, $grey300);
  width: fit-content;
}
.user-survey-btn-back {
  @include btnLargeSecondaryColored($primary500, $grey400);
}

.checkboxs-container {
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 8px;
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 15px;
  }
}

.slider-title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  p {
    color: $primary500 !important;
    font-size: 14px !important;
  }
}

.MuiInput-underline {
  border: none !important;
  &:before {
    border: none !important;
  }
  &:after {
    border: none !important;
  }
}

.os-user-survey-form {
  p {
    color: $grey600;
    font-size: 14px !important;
    margin-bottom: 4px;
  }
}

.user-survey-info {
  font-size: 12px;
  width: 100% !important;
  background-color: $secondary300;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  &-text {
    color: $secondary800;
  }
  &-title {
    font-size: 14px;
    font-weight: 600;
  }
}
.user-survey-info-link {
  color: $primary500;
  font-size: 12px;
  text-decoration: none;
}


.alert-text {
  color: $error500 !important;
  font-size: 13px !important;
  margin: 0;
}

.full-input.radio-inputs {
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 30px;
  }
}

.user-survey-question {
  color: $grey700 !important;
  margin-bottom: 12px !important;
}

.title-error-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.pdf-container {
  width: 80%;
  margin: 32px auto;
}

.upload-container {
  svg {
    color: $grey600;
  }
  .upload-title {
    margin: 0;
    color: $grey600;
  }

  .upload-content {
    color: $grey700;
  }
}

.uploaded-files {
  align-items: flex-start;
}

.investor-profile-card {
  @include card;
  background-color: $secondary300;
  box-shadow: none !important;
  height: auto;
  font-size: 12px;
}

.survey-top-container {
  justify-content: space-between;
  margin-bottom: 12px;
}

.iban-title-container {
  align-items: center;
  justify-content: space-between;
  & > button {
    margin-bottom: -5px;
  }
}

@include forTabletLandscapeUp {
  .user-survey-container {
    padding: 32px 48px 128px 48px;
    margin: auto;
  }
  .user-survey-questions-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
    column-gap: 16px; /* Space between grid items */
    row-gap: 12px;
    margin-bottom: 16px;
    .statement-checkbox {
      min-width: 2%;
      max-width: 2%;
    }
    .os-flex__column {
      justify-content: flex-end;
    }
  }

  .checkboxs-container {
    &-border-right {
      transition: 0.4s;
      width: 12% !important;
    }
  }

  .user-survey-btn-container {
    align-items: center;
  }

  .by-4 {
    width: 23% !important;
  }
  .by-3 {
    width: 30% !important;
  }
  .by-2 {
    width: 48.5% !important;
  }
  .by-1 {
    width: 100% !important;
  }
}
