@import "../../../../styles/common/colors";
@import "../../../../styles/common/buttons";
@import "../../../../styles/common/mixins";

.productSwiper {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  cursor: grab;
  position: relative;
  &:active {
    cursor: grabbing;
  }
  .swiper-slide {
    font-size: 18px;
    border-radius: 2px;
    height: 100%;
    object-fit: cover;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //background: linear-gradient(90deg, rgba(0,0,0,.1) 100%, rgba(0,0,0,0) 100%);
      z-index: 1;
      border-radius: 2px; // To match your swiper-slide border radius
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: grayscale(0%) brightness(100%) contrast(100%);
    }
  }


  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 20px;
    left: -120px;
    width: 100%;
  }
  span.swiper-pagination-bullet {
    background-color: $white !important;
    opacity: 0.4 !important;
  }

  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $grey200 !important;
    opacity: 1 !important;
  }
  .slider-btn {
    @include btnSecondaryColored($warning500, $grey400);
    width: 40%;
    font-weight: bold;
  }
  .slider-content {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: self-start;
    padding: 4px 16px 8px 16px;
    height: 10%;
    width: 97.5%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &-open-bg {
      background: rgba(#264C51, 0.7);
    }
    &-close-bg {
      background: rgba(#264C51, 0.7);
    }
    &-label {
      color: $white;
      opacity: 0.7;
      margin: 0;
    }
    &-title {
      color: $white;
      margin: 20px 0;
    }
  }
}

.absolute-chip {
  margin-top: 24px;
  margin-left: 24px;
}

.is-open {
  background-color: $success100;
  color: $success800;
  font-weight: bold;
  border-radius: 50px;
  padding: 6px 16px;
  width: fit-content;
  font-size: 11px;
}
.is-close {
  background-color: $warning100;
  color: $warning800;
  font-weight: bold;
  border-radius: 50px;
  padding: 6px 16px;
  width: fit-content;
  font-size: 11px;
}


@include forTabletPortraitUp {
  .productSwiper {
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      left: -130px;
      width: 50%;
    }
  }
}


@include forTabletLandscapeUp {
  .productSwiper {
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      left: -220px;
      width: 80%;
    }
  }
}



